<template>
  <div class="lxlt_lib">
    <div class="banner">
      <img src="~@/assets/images/libbanner.png" alt="" />
    </div>
    <div class="lib_header">
      <div class="inner">
        <div class="lib_title">资源库展示</div>
        <div class="lib_subtitle">探索无限可能：全面资源库精彩展示</div>
        <el-input
          class="search_inpit"
          placeholder="请输入资源库名称搜索"
          v-model="queryParams.title"
          @keyup.enter.native="handleQuery">
          <i slot="suffix" class="el-input__icon el-icon-search" style="cursor:pointer;" @click="handleQuery"></i>
        </el-input>
      </div>
    </div>
    <div class="inner">
      <div
        class="lib_list"
      >
        <div v-for="item in zykList" class="lib_item">
          <div class="lib_inner" @click="handleViewPath(item)">
            <el-image
              class="lib_img"
              :src="baseUrl + item.pic"
              fit="cover"
            ></el-image>
            <div class="pogress"></div>
            <div class="text_box">
              <div class="item_title txt-line1" :title="item.title">{{ item.title }}</div>
              <div class="item_subtitle txt-lin2">{{ item.content }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="page_box">
        <el-pagination
          v-if="total > 0"
          background
          :page-size="queryParams.pageSize"
          :hide-on-single-page="false"
          layout="prev, pager, next"
          :total="total"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </div>
      <el-empty
        v-if="zykList.length == 0"
        description="暂无资源库列表"
      ></el-empty>
    </div>
  </div>
</template>

<script>
import { listZyk } from "@/api/resourcelib";
import {scrollTo} from '@/utils/scroll-to'
export default {
  name: "ResourceLibCenter",
  data() {
    return {
      queryParams: {
        pageNum: 1,
        pageSize: 12,
        title: null,
        status: 0,
      },
      total: 0,
      loading: false,
      zykList: [],
      baseUrl: process.env.VUE_APP_BASE_API,
    };
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      this.loading = true;
      listZyk(this.queryParams).then((res) => {
        this.zykList = res.rows;
        this.total = res.total;
        this.loading = false;
      });
    },
    handleQuery(){
      this.queryParams.pageNum = 1;
      this.getList()
    },
    handleCurrentChange(val) {
      scrollTo(0,800)
      this.queryParams.pageNum = val;
      this.getList();
    },
    handleViewPath(item) {
      window.open(item.url, "_blank");
    },
  },
};
</script>

<style lang="scss" scoped>
.lxlt_lib {
  padding-bottom: 80px;
  .inner{
    position: relative;
  }
  .lib_header {
    padding: 76px 0 0px;
    line-height: 1;
    text-align: center;
  }
  .lib_title {
    font-weight: 800;
    font-size: 40px;
    margin-bottom: 31px;
  }
  .lib_subtitle {
    font-size: 16px;
    color: #999999;
  }
  .search_inpit{
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    width: 240px;
    ::v-deep .el-input__inner{
      border-radius: 42px;
      background-color: #F5F5F5;
    }
  }
  .lib_list {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 40px;
    margin-left: -30px;
    margin-right: -30px;
    .lib_item {
      width: 33.33%;
      padding: 0 30px;
      margin-top: 72px;
      margin-bottom: 4px;
      .lib_inner {
        position: relative;
        box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
        border-radius: 6px;
        transition: all 0.3s;
        overflow: hidden;
        cursor: pointer;
        height: 356px;
        .pogress {
          transition: all 0.3s;
          height: 5px;
          background: #005fc6;
          position: absolute;
          top: 0;
          width: 0;
          z-index: 10;
        }
        &:hover {
          .text_box {
            color: #fff;
            background-color: #000000ce;

            height: 356px;
          }
          .item_subtitle {
            height: 280px;
            display: block;
          }
          .pogress {
            width: 100%;
          }
          .lib_img {
            height: 356px;
            ::v-deep img {
              height: 356px;
            }
          }
        }
      }
      .lib_img {
        display: block;
        width: 100%;
        height: 240px;
        overflow: hidden;
        // height: 356px;
        ::v-deep img {
          height: 240px;
          transition: all 0.3s;
        }
      }
      .text_box {
        color: #333;
        height: 116px;
        bottom: 0;
        left: 0;
        width: 100%;
        background-color: #ffffffee;
        padding: 0 12px 20px;
        transition: all 0.3s;
        position: absolute;
        .item_title {
          font-weight: bold;
          font-size: 18px;
          line-height: 1;
          margin-bottom: 12px;
          padding-top: 26px;
          text-align: center;
        }
        .item_subtitle {
          font-size: 14px;
          line-height: 1.5;
          // height: 42px;
          overflow: hidden;
        }
      }
    }
  }
  .page_box{
    text-align: center;
  }
}
</style>
